<template>
  <section>
    <div class="no_compatible">
      <h1>
        Votre résolution d'ecran est trop faible pour utiliser la machine à
        sous, minimim requis 350 pixel horizontal
      </h1>
    </div>
    <!-- <pre v-show="$root.CommonVar.debug.ShowPreformatted" class="preformatted">
          DEBUG execSpin: {{ execSpin }} 
          busyCylinder: {{ busyMachine }} 
          busyCylinders: {{ $root.busyCylinders }} 
    DICES: [{{ dices[0] }} , {{ dices[1] }} , {{ dices[2] }}]</pre>-->
    <div class="slot-machine" :style="slotMachineStyle">
      <div class="slot-body">
        <div class="slot-trigger">
          <div class="arm">
            <div class="knob"></div>
          </div>
          <div class="arm-shadow"></div>
          <div class="ring1">
            <div class="shadow"></div>
          </div>
          <div class="ring2">
            <div class="shadow"></div>
          </div>
        </div>
        <div class="slot-frame linear_dark_background"></div>
        <div class="slot-wheels">
          <div class="wheel1 wheel">
            <MachineSlotRingSpin :vDice="dices[0]" :vExec="execSpin" :vDelay="0" />
          </div>
          <div class="wheel2 wheel">
            <MachineSlotRingSpin :vDice="dices[1]" :vExec="execSpin" :vDelay="50" />
          </div>
          <div class="wheel3 wheel">
            <MachineSlotRingSpin :vDice="dices[2]" :vExec="execSpin" :vDelay="100" />
          </div>
        </div>

        <div class="slot-play">
          <button
            :class="{ active_button: busyMachine <= 0 }"
            @click="getRandomDices"
            :disabled="busyMachine >= 1"
          ></button>
        </div>

        <div class="slot-extended">
          <!--  -->
          <div class="slot-jackpot">
            <div class="slot-jackpot-display linear_dark_background">
              <div class="slot-text lcd">Cagnotte</div>
              <div class="slot-overlay-line"></div>
              <div class="slot-credits gold">{{ showLcdDisplay(sold) }}€</div>
              <div class="slot-zeros"></div>
            </div>
            <div class="slot-jackpot-display linear_dark_background">
              <div :class="['slot-text', won > 0 ? 'color_jackpot' : 'lcd']">Gain</div>
              <div class="slot-overlay-line"></div>
              <div
                :class="['slot-credits', won > 0 ? 'color_jackpot' : 'lcd']"
              >{{ showLcdDisplay(won) }}€</div>
              <div class="slot-zeros"></div>
            </div>
            <div class="slot-jackpot-display linear_dark_background">
              <div class="slot-text lcd">Mise</div>
              <div class="slot-overlay-line"></div>
              <!-- <div :class="[ 'slot-credits', bet < 1 ? 'gold' : 'green' ]">{{ showLcdDisplay(bet) }}€</div> -->
              <div :class="['slot-credits', bet < 1 ? 'gold' : 'green']">
                <input
                  type="text"
                  :class="['input_money', bet < 1 ? 'gold' : 'green']"
                  v-model="bet"
                />€
              </div>
              <div class="slot-zeros"></div>
            </div>
          </div>

          <!--  -->
        </div>
        <div class="extended-frame">
          <table>
            <thead>
              <tr>
                <td>Symbole</td>
                <td>Gains</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(key, idx) in gain_table" :key="idx">
                <td>
                  <img :src="key.url" :alt="key.alt" />
                </td>
                <td
                  style="vertical-align: middle;"
                >{{ moneyFormatter(object.gain_table[key.alt]) }}€</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--  -->
      </div>

      <div class="slot-details">
        <div class="slot-top"></div>
        <div class="slot-bottom"></div>
      </div>
    </div>

    <!--  -->
  </section>
</template>

<script>
export default {
  components: {
    MachineSlotRingSpin: () => import("./MachineSlotRingSpin"),
  },
  props: {
    vId: {
      type: Number,
      default: 0,
    },
    center: { type: Boolean, default: false },
  },
  data() {
    return {
      dices: [0, 0, 0],
      object: {
        sold: 0,
        won: 0,
        gain_table: {
          "2cherries": 0,
          "3cherries": 0,
          "3oranges": 0,
          "3lemons": 0,
          "3jokers": 0,
          "3diamonds": 0,
          "3seven": 0,
        },
        bet: 0.5,
      },
      won: 0,
      sold: 0,
      execSpin: false,
      busyMachine: 0,
      timer: null,
      timertop10: null,
      retard_response_top10: false,
      gain_table: [
        {
          url: require("@/assets/img/machine_slots/2cherries.png"),
          alt: "2cherries",
        },
        {
          url: require("@/assets/img/machine_slots/3cherries.png"),
          alt: "3cherries",
        },
        {
          url: require("@/assets/img/machine_slots/3oranges.png"),
          alt: "3oranges",
        },
        {
          url: require("@/assets/img/machine_slots/3lemons.png"),
          alt: "3lemons",
        },
        {
          url: require("@/assets/img/machine_slots/3jokers.png"),
          alt: "3jokers",
        },
        {
          url: require("@/assets/img/machine_slots/3diamonds.png"),
          alt: "3diamonds",
        },
        {
          url: require("@/assets/img/machine_slots/3seven.png"),
          alt: "3seven",
        },
      ],
    };
  },
  created() {
    this.$root.busyCylinders = 0;
  },
  mounted() {
    this.getSold();
    this.timerGetSold();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    clearTimeout(this.timertop10);
  },
  computed: {
    bet() {
      return this.object && this.object.bet
        ? this.moneyFormatter(this.object.bet)
        : 0.0;
    },
    slotMachineStyle() {
      return this.center ? "margin: 0 auto;" : "";
    },
  },
  watch: {
    busyMachine() {
      if (this.busyMachine < 0) this.busyMachine = 0;

      this.$root.busyCylinders = this.busyMachine;
      clearTimeout(this.timer);
      clearTimeout(this.timertop10);

      if (this.busyMachine === 0) {
        this.timerGetSold();
        this.won = this.object.won;
        this.sold = this.moneyFormatter(this.object.sold);
        this.retard_response_top10 = true;
      } else {
        this.won = 0;
      }
    },
  },
  methods: {
    showLcdDisplay(number = 0) {
      return number > 999999 ? ">999999.00" : this.moneyFormatter(number);
    },
    moneyFormatter(value) {
      return Number.parseFloat(value).toFixed(2);
    },
    timerGetSold() {
      this.timer = setTimeout(() => {
        this.getSold();
        this.timerGetSold();
      }, 1000);
    },
    async getSold() {
      // const data = await this.$root.axios("get", "machine/slot", {
      //   id: this.vId,
      // });

      const data = await this.$root.handleGetRequest("machine/slot", true, {
        machine_slot_id: this.vId,
      });

      const Obj = data && data["machine_slots"] ? data["machine_slots"] : null;

      if (Obj && Obj.sold) {
        this.sold = this.moneyFormatter(Obj.sold);
        this.object = Obj;

        if (this.$root.busyCylinders === 0) {
          if (this.retard_response_top10) {
            this.retard_response_top10 = false;
            this.timertop10 = setTimeout(() => {
              this.$parent.top10 = Obj.top10;
            }, 3000);
          } else {
            this.$parent.top10 = Obj.top10;
          }
        }
      }
    },
    async getRandomDices() {
      const data = await this.$root.axios("put", "machine/slot", {
        machine_slot_id: this.vId,
        roll: 1,
        top10: 1,
      });

      const Obj = data && data["machine_slots"] ? data["machine_slots"] : null;

      if (Obj && Obj.dice1 && Obj.dice2 && Obj.dice3 && Obj.sold) {
        this.object = Obj;
        this.dices = [Obj.dice1, Obj.dice2, Obj.dice3];
        this.execSpin = true; // J'ai obtenu les dés alors je lance la roulette
      }
    },
  },
};
</script>

<style scoped>
@-webkit-keyframes wheelPlayButton {
  from,
  to {
    background: rgb(255, 255, 0);
  }
  10% {
    background: rgb(255, 200, 0);
  }
  20% {
    background: rgb(255, 150, 0);
  }
  30% {
    background: rgb(255, 100, 0);
  }
  40% {
    background: rgb(255, 50, 0);
  }
  50% {
    background: rgb(255, 0, 0);
  }
  60% {
    background: rgb(255, 50, 0);
  }
  70% {
    background: rgb(255, 100, 0);
  }
  80% {
    background: rgb(255, 150, 0);
  }
  90% {
    background: rgb(255, 200, 0);
  }
}
@keyframes wheelPlayButton {
  from,
  to {
    background: rgb(255, 255, 0);
  }
  10% {
    background: rgb(255, 200, 0);
  }
  20% {
    background: rgb(255, 150, 0);
  }
  30% {
    background: rgb(255, 100, 0);
  }
  40% {
    background: rgb(255, 50, 0);
  }
  50% {
    background: rgb(255, 0, 0);
  }
  60% {
    background: rgb(255, 50, 0);
  }
  70% {
    background: rgb(255, 100, 0);
  }
  80% {
    background: rgb(255, 150, 0);
  }
  90% {
    background: rgb(255, 200, 0);
  }
}
@-webkit-keyframes wheelJackpotColor {
  from,
  to {
    color: rgb(255, 255, 0);
  }
  10% {
    color: rgb(255, 200, 0);
  }
  20% {
    color: rgb(255, 150, 0);
  }
  30% {
    color: rgb(255, 100, 0);
  }
  40% {
    color: rgb(255, 50, 0);
  }
  50% {
    color: rgb(255, 0, 0);
  }
  60% {
    color: rgb(255, 50, 0);
  }
  70% {
    color: rgb(255, 100, 0);
  }
  80% {
    color: rgb(255, 150, 0);
  }
  90% {
    color: rgb(255, 200, 0);
  }
}
@keyframes wheelJackpotColor {
  from,
  to {
    color: rgb(255, 255, 0);
  }
  10% {
    color: rgb(255, 200, 0);
  }
  20% {
    color: rgb(255, 150, 0);
  }
  30% {
    color: rgb(255, 100, 0);
  }
  40% {
    color: rgb(255, 50, 0);
  }
  50% {
    color: rgb(255, 0, 0);
  }
  60% {
    color: rgb(255, 50, 0);
  }
  70% {
    color: rgb(255, 100, 0);
  }
  80% {
    color: rgb(255, 150, 0);
  }
  90% {
    color: rgb(255, 200, 0);
  }
}

/*   IL Y A UN BUG ENTRE CES LIGNES */

.no_compatible {
  display: none;
}
.no_compatible h1 {
  text-shadow: 0 0 0 transparent;
}

.slot-machine {
  border-radius: 10px;
  box-shadow: 0 1px 100px rgba(0, 0, 0, 0.6), 0 1px 2px rgba(0, 0, 0, 0.8);
  margin-left: -50px;
  height: 750px;
  /* margin: 50px auto; */
  /* margin-left: 50%; */
  position: relative;
  width: 450px;
}

.slot-body {
  background: darkslategrey;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid darkslategrey;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: inset 0 0 25px rgba(255, 255, 255, 0.6);
  height: 750px;
  margin: 50px auto;
  position: relative;
  width: 100%;
  z-index: 2;
}

.slot-frame {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.3),
    0 1px 1px rgba(255, 255, 255, 0.5), 0 -1px 1px rgba(255, 255, 255, 0.2),
    inset 0 -2px 15px #000;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid darkslategrey;
  height: 290px;
  left: 25%;
  margin-left: -85px;
  position: absolute;
  top: 10px;
  width: 89%;
  z-index: 1;
}

.slot-wheels {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  align-self: flex-start;
  height: 245px;
  overflow: hidden;
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 2;
}

.slot-wheels .wheel {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  align-self: flex-start;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  height: 386px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 22%;
}

.wheel1 {
  left: 50px;
}
.wheel2 {
  left: 175px;
}
.wheel3 {
  left: 300px;
}

.slot-play {
  position: absolute;
  left: 22px;
  top: 285px;
  z-index: 1;
}
.slot-play button {
  display: block;
  margin-left: 25%;
  width: 75px;
  height: 75px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  letter-spacing: -1.5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  box-shadow: 0 9px #1b1b1b;
  background: slategray;
  background: radial-gradient(circle, slategray 50%, darkslategray 100%);
  transition: 0.5s;
}
.slot-play button::before {
  font-size: 20px;
  font-weight: bold;
  color: #1b1b1b;
  text-transform: uppercase;
  text-shadow: 0 0 3px dimgray;
  content: "Jouer";
}
.slot-play button:hover {
  background: dimgray;
  background: radial-gradient(circle, dimgray 50%, darkslategray 100%);
  transition: 0.1s;
}
.slot-play .active_button:active {
  animation: wheelPlayButton 0.5s infinite;
  box-shadow: 0 5px #1b1b1b;
  transform: translateY(4px);
  transition: 0.01s;
}

.slot-extended {
  position: absolute;
  right: 22px;
  top: 310px;
}

.extended-frame {
  position: relative;
  top: 400px;
  margin: 0 20%;
  padding: 0;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: #fbfbfb;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.3),
    0 1px 1px rgba(255, 255, 255, 0.5), 0 -1px 1px rgba(255, 255, 255, 0.2),
    inset 0 -2px 15px #000;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid darkslategrey;
}
.extended-frame table {
  overflow-x: auto;
  border-collapse: collapse;
  border: none;
  margin: auto;
  width: 92%;
}
.extended-frame tr:nth-child(even) {
  background-color: lightgrey;
}

.extended-frame th {
  text-align: center;
}
.extended-frame td,
th {
  vertical-align: middle;
  color: darkslategray;
  text-shadow: 0 0 0 transparent;
  font-family: "Courier New", Courier, monospace;
  font-size: 18px;
  font-weight: bold;
}
.slot-jackpot {
  position: relative;
  top: 0;
  left: 0;
}
.slot-jackpot-display {
  width: 265px;
  margin: 0;
  height: 28px;
  position: relative;
  right: 0;
  bottom: 0;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.3),
    0 1px 1px rgba(255, 255, 255, 0.5), 0 -1px 1px rgba(255, 255, 255, 0.2),
    inset 0 -2px 15px #000;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid darkslategrey;
  z-index: 2;
}

.slot-credits {
  position: absolute;
  right: 4px;
  top: 1px;
  z-index: 2;
  letter-spacing: 3px;
  line-height: 25px;
  font-family: "Courier New", Courier, monospace;
  font-size: 18px;
  text-align: right;
  font-weight: bold;
  box-shadow: none;
}
.input_money {
  background: transparent;
  width: 7em;
  color: white;
  letter-spacing: 3px;
  line-height: 25px;
  font-family: "Courier New", Courier, monospace;
  font-size: 18px;
  text-align: right;
  font-weight: bold;
  border: 0;
}

/*   IL Y A UN BUG ENTRE CES LIGNES */

.lcd {
  color: lightsteelblue;
}
.green {
  color: lime;
}
.gold {
  color: gold;
}
.red {
  color: red;
}
.color_jackpot {
  color: yellow;
  animation: wheelJackpotColor 0.5s infinite;
}
.slot-text {
  position: absolute;
  left: 5px;
  top: 1px;
  letter-spacing: 1.5px;
  line-height: 25px;
  font-family: "Courier New", Courier, monospace;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 2;
  box-shadow: none;
}
.slot-zeros::after {
  position: absolute;
  right: 4px;
  top: 1px;
  color: rgba(255, 255, 255, 0.3);
  z-index: 1;
  letter-spacing: 3px;
  line-height: 25px;
  font-family: "Courier New", Courier, monospace;
  font-size: 18px;
  text-align: right;
  content: "8888888888";
  box-shadow: none;
}

.slot-overlay {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.1)),
    color-stop(50%, rgba(0, 0, 0, 0.15)),
    color-stop(51%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 0))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
}

.slot-overlay-line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  height: 46%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

.slot-trigger {
  height: 85px;
  right: -40px;
  top: 115px;
  position: absolute;
  width: 9%;
}

.slot-trigger div {
  position: absolute;
  top: 0;
}

.slot-trigger .ring1 {
  background: #282828;
  background: -moz-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #282828),
    color-stop(14%, #959595),
    color-stop(37%, #d1d1d1),
    color-stop(49%, #bababa),
    color-stop(67%, #959595),
    color-stop(100%, #212121)
  );
  background: -webkit-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: -o-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: -ms-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: linear-gradient(
    to bottom,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  -moz-border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
  height: 100%;
  left: 0;
  width: 20%;
  z-index: 2;
}

.slot-trigger .ring2 {
  background: #282828;
  background: -moz-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #282828),
    color-stop(14%, #959595),
    color-stop(37%, #d1d1d1),
    color-stop(49%, #bababa),
    color-stop(67%, #959595),
    color-stop(100%, #212121)
  );
  background: -webkit-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: -o-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: -ms-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: linear-gradient(
    to bottom,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  -moz-border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
  height: 80%;
  left: 8px;
  overflow: hidden;
  top: 10%;
  /* width: 10px; */
  width: 30%;
  z-index: 1;
}

.slot-trigger .arm {
  background: #0d0d0d;
  background: -moz-linear-gradient(
    left,
    #0d0d0d 0%,
    #4e4e4e 47%,
    #383838 87%,
    #1b1b1b 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, #0d0d0d),
    color-stop(47%, #4e4e4e),
    color-stop(87%, #383838),
    color-stop(100%, #1b1b1b)
  );
  background: -webkit-linear-gradient(
    left,
    #0d0d0d 0%,
    #4e4e4e 47%,
    #383838 87%,
    #1b1b1b 100%
  );
  background: -o-linear-gradient(
    left,
    #0d0d0d 0%,
    #4e4e4e 47%,
    #383838 87%,
    #1b1b1b 100%
  );
  background: -ms-linear-gradient(
    left,
    #0d0d0d 0%,
    #4e4e4e 47%,
    #383838 87%,
    #1b1b1b 100%
  );
  background: linear-gradient(
    to right,
    #0d0d0d 0%,
    #4e4e4e 47%,
    #383838 87%,
    #1b1b1b 100%
  );
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.8);
  height: 50%;
  left: 10px;
  top: -25px;
  width: 15%;
  z-index: 3;
  overflow: visible !important;
}

.slot-trigger .arm .knob {
  background: #ff6363;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #ff6363 0%,
    #cf0404 100%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, #ff6363),
    color-stop(100%, #cf0404)
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #ff6363 0%,
    #cf0404 100%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    #ff6363 0%,
    #cf0404 100%
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    #ff6363 0%,
    #cf0404 100%
  );
  background: radial-gradient(ellipse at center, #ff6363 0%, #cf0404 100%);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  height: 16px;
  left: -7px;
  top: -15px;
  width: 20px;
  z-index: 4;
}

.slot-trigger .arm-shadow {
  background: #000;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  bottom: 66px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  height: 6px;
  left: 9px;
  top: auto;
  width: 8px;
  z-index: 2;
}

.slotTrigger .slotTriggerDown {
  background-position: 0px -17px;
}
.slotTrigger .slotTriggerDisabled {
  background-position: 0px -34px;
  display: none;
}
.slotSpinAnimation {
  display: none;
}

.slot-details {
  height: 350px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.slot-top {
  background: #414244;
  border-radius: 10px 10px 0 0;
  border: 2px solid darkslategrey;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.6), 0 1px 2px rgba(0, 0, 0, 0.8),
    inset 0 0 25px rgba(255, 255, 255, 0.2);
  height: 12px;
  left: 50%;
  margin-left: -98px;
  position: absolute;
  top: -3px;
  width: 196px;
}

.slot-bottom {
  background: darkslategrey;
  border-radius: 0 0 10px 10px;
  border: 2px solid darkslategrey;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.6), 0 1px 2px rgba(0, 0, 0, 0.8),
    inset 0 0 25px rgba(255, 255, 255, 0.2);
  height: 12px;
  left: 50%;
  margin-left: -98px;
  position: absolute;
  bottom: -3px;
  width: 196px;
}

/*
 *************************  Media Queries *************************
  color: gestion de la couleur (en bits/pixel).
  height: hauteur de la zone d'affichage (fenêtre).
  width: largeur de la zone d'affichage (fenêtre).
  device-height: hauteur du périphérique.
  device-width: largeur du périphérique.
  orientation: orientation du périphérique (portrait ou paysage).
  media: type d'écran de sortie. Quelques-unes des valeurs possibles :
    screen: écran « classique » ;
    handheld: périphérique mobile ;
    print: impression ;
    tv: télévision ;
    projection: projecteur ;
    all: tous les types d'écran.
*/
@media screen and (max-width: 700px) {
  .slot-machine {
    width: 350px;

    /* margin: 0; */
    margin: 0 auto;
    padding: 0;
    box-shadow: 0 0 0 transparent;
  }
  .slot-body {
    margin-left: -15px;
  }
  .slot-wheels {
    height: 245px;
    top: 30px;
    width: 100%;
  }

  .slot-wheels .wheel {
    height: 386px;
    top: 0;
    width: 98px;
  }

  .slot-frame {
    height: 290px;
    left: 0%;
    margin-left: 4px;
    top: 10px;
    width: 98%;
  }
  .wheel1 {
    left: 20px;
  }
  .wheel2 {
    left: 125px;
  }
  .wheel3 {
    left: 230px;
  }

  .slot-jackpot {
    right: 10px;
  }

  .slot-trigger,
  .ring1,
  .ring2,
  .arm,
  .knob,
  .arm-shadow {
    display: none;
  }

  .slot-jackpot-display {
    width: 220px;
  }
  .slot-credits {
    letter-spacing: 3px;
    line-height: 25px;
    font-size: 14px;
    font-weight: bold;
  }
  .input_money {
    width: 8.2em;
    letter-spacing: 3px;
    line-height: 25px;
    font-size: 14px;
    font-weight: bold;
  }
  .slot-text {
    letter-spacing: 1.5px;
    font-size: 14px;
    line-height: 25px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .slot-zeros::after {
    letter-spacing: 3px;
    line-height: 25px;
    font-size: 14px;
    content: "88888888888";
  }

  .slot-bottom {
    display: none;
  }
  .slot-play button {
    margin-left: 0;
  }
  .slot-play button::before {
    font-size: 15px;
  }

  .extended-frame {
    margin: 0 22px;
  }
}
@media screen and (max-width: 349px) {
  .slot-machine,
  .slot-wheels,
  .slot-wheels,
  .wheel,
  .slot-frame,
  .wheel1,
  .wheel2,
  .wheel3,
  .slot-jackpot,
  .slot-trigger,
  .ring1,
  .ring2,
  .arm,
  .knob,
  .arm-shadow {
    display: none;
  }
  .no_compatible {
    display: block;
    background: yellow;
    color: red;
    font-weight: bold;
    padding: 0em 1em;
  }
}

.linear_dark_background {
  background: #1b1b1b;
  background: -moz-linear-gradient(
    top,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #1b1b1b),
    color-stop(8%, #383838),
    color-stop(20%, #3a3a3a),
    color-stop(47%, #0a0a0a),
    color-stop(50%, #010101),
    color-stop(54%, #0d0d0d),
    color-stop(100%, #444444)
  );
  background: -webkit-linear-gradient(
    top,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  background: -o-linear-gradient(
    top,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  background: -ms-linear-gradient(
    top,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  background: linear-gradient(
    to bottom,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
}
</style>
